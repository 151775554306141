import axios from "axios";

export const ApiBase = axios.create({
  baseURL: "https://ld-api.matehus.dev/api",
  withCredentials: false,
});

export type DisciplineEnum = 'GEO' | 'MAT'
export interface Unit {
  id: string;
  name: string;
  code: string;
  chapters: Chapter[];
  discipline: DisciplineEnum
}

export interface Book {
  id: string;
  name: string;
  cover: string;
  units: Unit[];
}
export interface IContentResponse {
  geral: string;
  books: Book[];
}

export async function getContent() {
  return ApiBase.get<IContentResponse>("/content");
}

export const geralPath =
  "https://nyc3.digitaloceanspaces.com/ra-sae/LD_TESTE/geral.zip";
export const chapterPath =
  "https://nyc3.digitaloceanspaces.com/ra-sae/LD_TESTE/content/EF2/CIE/EF22_6_CIE_L1_U1_02/html/index.html";
export const chaptersBasePath = "/proxy/content/EF2/";
export const geralBasePath = "/proxy/content/";

export const cdnPath =
  "https://cdn-livrodigital.nyc3.cdn.digitaloceanspaces.com/Conteudo_SAE_2022";

export interface ChapterListResponse {
  base_path: string;
  chapters: {
    package: string;
    code: string;
  }[];
}

export interface Chapter {
  id: string;
  code: string;
  name: string;
  package: string;
}

export interface Discipline {
  id: string;
  nome: string;
  slug: string;
  series: string;
}

import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios, { Axios, AxiosError } from "axios";
import { useSnackbar } from "notistack";
import React, {
  createContext,
  EventHandler,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ApiBase } from "../api";
import { ReactComponent as SaeLogo } from "../assets/logo.svg";
import BookLogo from "../assets/bookLogo.png";

interface LoginResponse {
  loggedIn: boolean;
  name?: string;
  grades: string[];
}

export interface ILoginContext extends LoginResponse {
  logOut(): void;
}

export const LoginContext = createContext<ILoginContext | null>(null);

export const LoginContextProvider: React.FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loginStatus, setLoginStatus] = useState<null | LoginResponse>(null);
  const [user, setUser] = useState("");

  const value = useMemo<ILoginContext>(
    () => ({
      loggedIn: loginStatus?.loggedIn ?? false,
      name: loginStatus?.name,
      grades: ["7º ano"],
      logOut: () => {
        localStorage.removeItem("login");
        setLoginStatus(null);
      },
    }),
    [loginStatus]
  );

  const doLogin = async (_user: string) => {
    try {
      const login = await ApiBase.post<LoginResponse>("/login", {
        user: _user,
      });
      if (login && login.data.loggedIn) {
        setLoginStatus(login.data);
        localStorage.setItem("login", _user);
      }
      console.log(login);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        enqueueSnackbar((err as any).response?.data?.message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar((err as Error).toString(), {
          variant: "error",
        });
      }
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    doLogin(user);
  };

  useEffect(() => {
    const _user = window.location.hash
    console.log(_user)
    if ((_user && _user.includes('login=')) || window.location.hostname === 'localhost') {
      setLoginStatus({
        loggedIn: true,
        name: (_user || 'nome').replace('#login=', ''),
        grades: ["7º ano"],
      });
      window.localStorage.setItem('_user', (_user || 'Nome').replace('#login=', ''),)
      window.location.replace('#')
    } else if (window.localStorage.getItem('_user') && window.localStorage.getItem('_user') !== '') {
      setLoginStatus({
        loggedIn: true,
        name: window.localStorage.getItem('_user') || 'Nome',
        grades: ["7º ano"],
      });
    } else {
      window.location.href = 'https://avinha.netlify.app/'
    }
  }, []);

  useEffect(() => {
    if (!value.loggedIn) {
      
    }
  }, [value])

  return (
    <LoginContext.Provider value={value}>
      {value.loggedIn ?  children : <div></div>}
    </LoginContext.Provider>
  );
};

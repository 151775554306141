import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
  shape: {
    borderRadius: 18,
  },
  palette: {
    background: {
      default: '#FDCE65'
    },
    primary: {
      main: "#ff6748",
    },
    secondary: {
      main: "#fcb833",
    },
    success: {
      main: '#5CB85C',
      contrastText: 'white'
    }
  },
});

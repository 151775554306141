import { CircularProgress, Grid } from "@mui/material";
import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { DownloadOverlay } from "./components/DownloadOverlay";
import { LoginContextProvider } from "./components/LoginContext";
import NavBar from "./components/NavBar";
import { DownloadManagerProvider } from "./DownloadManagerContext";
import BookPage from "./pages/BookPage";
import BooksPage from "./pages/Books";
import ChapterPage from "./pages/ChapterPage";
import { SnackbarProvider, useSnackbar } from "notistack";
import { register } from "./serviceWorkerRegistration";

function App() {
  const { enqueueSnackbar} = useSnackbar()
  useEffect(() => {
    // const loader = document.querySelector<HTMLDivElement>(".loader");
    // if (loader) {
    //   loader.style.opacity = "0";
    //   setTimeout(() => {
    //     loader.remove();
    //   }, 120);
    // }

    register({
      onUpdate: () => {
        // enqueueSnackbar('Nova versão do livro digital disponível. Feche esta página e abra novamente para atualizar')
      },
      onSuccess: () => {
        enqueueSnackbar('Aplicativo instalado e pronto para uso offline!')
      }
    });
  }, []);

  return (
      <LoginContextProvider>
        <DownloadManagerProvider>
          <Router>
            <NavBar />
            <Routes>
              <Route
                path="/books/:unit/chapters/:chapter"
                element={<ChapterPage />}
              />
              <Route path="/books/:id" element={<BookPage />} />
              <Route path="/" element={<BooksPage />} />
            </Routes>
          </Router>
          <DownloadOverlay />
        </DownloadManagerProvider>
      </LoginContextProvider>
  );
}

export default App;

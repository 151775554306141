import React, { useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import InstallMobileRoundedIcon from "@mui/icons-material/InstallMobileRounded";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import {
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  LinearProgress,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  styled,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import prettyBytes from "pretty-bytes";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import BookMarkFill from "../assets/bookmark.svg";
import { ReactComponent as BookMarkTip } from "../assets/bookmarkTip.svg";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { LoginContext } from "./LoginContext";
import { useSnackbar } from "notistack";

interface BeforePromptEvent {
  prompt(): Promise<void>;
}

const BookMarkFrame = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const BookMarkFillFrame = styled("div")`
  background: url(${BookMarkFill});
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: 100%;
`;

const MenuListWrapper = styled("div")<{
  opened: boolean;
}>`
  background: #e6e7e7;
  width: 100%;
  margin-bottom: 130px;
  margin-top: ${(p) => (p.opened ? 0 : "-20%")};
  transition: margin-top 300ms ease-in-out;
  border-radius: 0 0 18px 18px;
  padding-bottom: 4px;

  & > div {
    width: 100%;
  }
`;

export default function NavBar() {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const loginContext = useContext(LoginContext)

  const [menuOpen, setMenuOpen] = useState(false);
  const [isOnline, setIsOnline] = useState(true);
  const [add2HS, setAdd2HS] = useState<any | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [storageData, setStorageData] = useState<null | StorageEstimate>(null);
  const [updateModal, setUpdateModal] = useState(false);

  const handleOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    navigator.storage.estimate().then((estimate) => {
      setStorageData(estimate);
    });
  };
  const handleOptionsClose = () => setAnchorEl(null);

  React.useEffect(() => {
    window.addEventListener("online", () => setIsOnline(true));
    window.addEventListener("offline", () => setIsOnline(false));

    window.addEventListener("beforeinstallprompt", (e: any) => {
      e.preventDefault();
      // @ts-ignore
      window.bip = e;
      setAdd2HS(e);
    });

    setIsOnline(navigator.onLine);

    if (localStorage.getItem('showUpdated') === 'true') {
      localStorage.removeItem('showUpdated')
      snackbar.enqueueSnackbar('Aplicativo atualizado!')
    }
  }, []);

  const update = async () => {
    try {
      setUpdateModal(true);
      await fetch(
        "https://ld-api.matehus.dev/api/define?code=MAT&available=true"
      );
      localStorage.setItem('showUpdated', 'true')
      setTimeout(() => {
        setUpdateModal(false)
        window.location.href = '/#login=' + loginContext?.name
      }, 850);
    } catch (err) {
      snackbar.enqueueSnackbar((err as any as Error).toString(), {
        variant: "error",
      });
      console.error(err);
    }
  };

  const storagePercent = storageData
    ? ((storageData.usage ?? 0) / (storageData.quota ?? 0)) * 100
    : 0;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Container maxWidth="md">
        <Grid container flexWrap="nowrap">
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            <BookMarkFrame>
              <BookMarkFillFrame>
                <IconButton size="large" onClick={() => setMenuOpen((v) => !v)}>
                  <MenuRoundedIcon
                    sx={{
                      color: "white",
                    }}
                    fontSize="large"
                  />
                </IconButton>
              </BookMarkFillFrame>
              <BookMarkTip />
            </BookMarkFrame>
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <MenuListWrapper opened={menuOpen}>
              <Grid container direction="column">
                <Grid item>
                  <ListItemButton onClick={() => navigate("/")} dense>
                    <ListItemIcon>
                      <HomeRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Página inicial" />
                  </ListItemButton>
                </Grid>
                <Divider />
                {/* {add2HS && [
                  <Grid item>
                    <ListItemButton onClick={() => add2HS.prompt()} dense>
                      <ListItemIcon>
                        <InstallMobileRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Instalar na area de trabalho" />
                    </ListItemButton>
                  </Grid>,
                  <Divider />,
                ]} */}
                <Grid item>
                  <ListItemButton
                    onClick={() => {
                      window.location.href = "https://avinha.netlify.app/";
                    }}
                    dense
                  >
                    <ListItemIcon>
                      <LogoutRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sair" />
                  </ListItemButton>
                </Grid>
                <Divider />
                <Grid item>
                  <ListItemButton onClick={update} dense>
                    <ListItemIcon>
                      <RefreshRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Atualizar" />
                  </ListItemButton>
                </Grid>
                {/* <Grid item>
                  <ListItem dense>
                    <ListItemText
                      primary={`Versão ${process.env.REACT_APP_VERSION}`}
                    />
                  </ListItem>
                </Grid> */}
              </Grid>
            </MenuListWrapper>
          </Grid>
        </Grid>
      </Container>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleOptionsClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box m={2}>
          {storageData && (
            <Grid container direction="column">
              <Grid item>
                {prettyBytes(storageData.usage ?? 0)} /{" "}
                {prettyBytes(storageData.quota ?? 0)}
              </Grid>
              <Grid item>
                {prettyBytes(
                  (storageData.quota ?? 0) - (storageData.usage ?? 0)
                )}{" "}
                restante
              </Grid>
              <Grid item>
                <LinearProgress
                  variant="determinate"
                  value={storagePercent}
                  sx={{
                    width: "200px",
                  }}
                />
                {storagePercent.toFixed(2)}%
              </Grid>
            </Grid>
          )}
        </Box>
      </Menu>
      <Dialog open={updateModal} onClose={() => setUpdateModal(false)}>
        <DialogTitle>Atualizando...</DialogTitle>
        <DialogContent>
          <Box mx={18} my={4}>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

/*
  return <IconButton
  size="large"
  edge="start"
  color="inherit"
  aria-label="menu"
  sx={{ mr: 2 }}
  onClick={() => {
    navigate(-1);
  }}
>
  <ArrowBackRoundedIcon />
</IconButton>
<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
  Livro Digital Teste {process.env.REACT_APP_VERSION}
</Typography>
<Grid container alignItems="center" sx={{ width: "auto" }}>
  <Grid item>
    {add2HS && (
      <IconButton onClick={() => add2HS.prompt()}>
        <InstallMobileRoundedIcon />
      </IconButton>
    )}
  </Grid>

  <Grid
    item
    sx={{
      width: 24,
      height: 24,
    }}
  >
    {!isOnline && <CloudOffIcon />}
  </Grid>
  <Grid item>
    <IconButton onClick={handleOptionsClick}>
      <MoreVertIcon />
    </IconButton>
  </Grid>
</Grid>
*/

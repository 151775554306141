import { styled } from "@mui/material";
import React from "react";

export const ColoredBar = styled('div')`
  background: ${p => p.theme.palette.primary.main};
  color: white;
  padding: 6px;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
`
import {
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import { DownloadManagerContext } from "../DownloadManagerContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const OverlayWrapper = styled("div")`
  position: fixed;
  right: 24px;
  bottom: 24px;
  /* max-width: 200px; */
`;

const QueueItems = () => {
  const downloadManager = useContext(DownloadManagerContext);

  return (
    <React.Fragment>
      {downloadManager &&
        [...downloadManager.currentQueue.values()].map((queue) => (
          <Grid
            container
            key={queue.id}
            justifyContent="space-between"
            // alignItems="center"
            wrap="nowrap"
            sx={{
              width: "100%",
            }}
          >
            <Grid item sx={{ marginRight: 1 }}>
              {queue.id}
            </Grid>
            <Grid item>
              <Box
                sx={{
                  height: "18px",
                }}
              >
                {queue.complete ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <CircularProgress
                    variant={
                      queue.progress === 0 || queue.progress === 100
                        ? "indeterminate"
                        : "determinate"
                    }
                    value={queue.progress}
                    size="18px"
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        ))}
    </React.Fragment>
  );
};

export const DownloadOverlay = () => {
  const downloadManager = useContext(DownloadManagerContext);
  const [opened, setOpened] = useState(true);
  console.log("render");
  return downloadManager && downloadManager.currentQueue.size > 0 && false ? (
    <OverlayWrapper>
      <Card variant="outlined">
        <Box p={1.6}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems='center'
            sx={{
              width: "100%",
            }}
          >
            <b>Downloads</b>
            <IconButton onClick={() => setOpened(p => !p)}>
              <KeyboardArrowDownRoundedIcon
                sx={{
                  transition: 'all 300ms ease-in-out',
                  transform: `rotate(${!opened ? 180 : 0}deg)`
                }}
              />
            </IconButton>
          </Box>
          <Collapse in={opened}>
            <Box mt={1}>
              <QueueItems />
            </Box>
          </Collapse>
        </Box>
      </Card>
    </OverlayWrapper>
  ) : (
    <div />
  );
};

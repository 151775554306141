import {
  Card,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { createElement, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ApiBase,
  Chapter,
  ChapterListResponse,
  chaptersBasePath,
  geralBasePath,
  geralPath,
  getContent,
} from "../api";
import prettyBytes from "pretty-bytes";
import { unzip } from "unzipit";
import mime from "mime";
import { Workbox } from "workbox-window";
import { chunkArray } from "../utils";
import { useSnackbar } from "notistack";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import FormatColorTextRoundedIcon from "@mui/icons-material/FormatColorTextRounded";
import NotesRoundedIcon from "@mui/icons-material/NotesRounded";
import { basePath, downloadChapter, downloadGeral } from "../geral";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ListRoundedIcon from "@mui/icons-material/ListRounded";
import { Box } from "@mui/system";

enum IndexType {
  HEADER1,
  HEADER2,
}

interface IndexItem {
  text: string;
  type: IndexType;
}

const ContentFrame = styled("iframe")`
  width: 100%;
  height: 100%;
  border: none;
  background: white;
`;

const ToolboxFrame = styled("div")`
  position: absolute;
  top: 0;
  right: 10px;
`;

const ToolboxCard = styled("div")`
  background: ${(p) => p.theme.palette.primary.main};
  box-shadow: 0px 0px 60px rgba(68, 68, 68, 0.6);
  padding: 6px;
  border-top: 0;
  margin-right: 20px;
  border-radius: 0 0 18px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface GeralResponse {
  version: number;
  base_path: string;
  files: string[];
  size: number;
}

function ChapterPage() {
  const { unit: unitId, chapter: chapterId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [indexes, setIndexes] = useState<null | IndexItem[]>(null);

  const [toolboxOpened, setToolboxOpened] = useState(false);
  const [selectedTool, setSelectedTool] = useState<
    null | "PARAGRAPH_HIGHLIGHT" | "SELECTION"
  >(null);

  const [indexAnchorEl, setIndexAnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  const [chapterData, setChapter] = useState<Chapter | null>(null);
  const [progress, setProgress] = useState<null | {
    text: string;
    value: number;
    total: number;
  }>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [contentUrl, setContentUrl] = useState<string | null>(null);
  const [frameLoaded, setFrameLoaded] = useState(false);

  const load = async () => {
    try {
      const content = await getContent();

      await downloadGeral(content.data, setProgress);

      const chapter = content.data.books
        .map((b) => b.units)
        .flat()
        .map((u) => u.chapters)
        .flat()
        .find((c) => c.id === chapterId);

      if (chapter) {
        const chapterCache = await caches.open(`cache-${chapter.id}`);

        const keys = await chapterCache.keys();
        let indexFile = null;
        if (keys.length > 0) {
          indexFile =
            keys.find((r) => r.url.includes("html/index.html"))?.url ?? null;
        } else {
          indexFile =
            basePath +
            (await downloadChapter(chapter, (event) => {
              setProgress({
                text: `Baixando capítulo`,
                total: event.total,
                value: (event.loaded / event.total) * 100,
              });
            }));
        }

        setProgress(null);

        setContentUrl(indexFile);
      } else {
        enqueueSnackbar("Capitulo nao existente");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const selectTool = (tool: "PARAGRAPH_HIGHLIGHT" | "SELECTION") => () => {
    if (selectedTool === tool) setSelectedTool(null);
    else setSelectedTool(tool);
  };

  console.log("chapter render");

  useEffect(() => {
    if (!iframeRef.current) return;
    const document = iframeRef.current.contentDocument;
    const paragraphs = document?.querySelectorAll("p");
    if (selectedTool === "PARAGRAPH_HIGHLIGHT") {
      const style = document?.createElement("style")!;
      style.innerHTML = `
      p.selecteableParagraph:hover { background: #fcb83322 }
      p.selectedParagraph { background: #fcb83355 }
      `;
      document?.head.appendChild(style);
      for (const paragraph of paragraphs?.values()!) {
        paragraph.classList.add("selecteableParagraph");
        paragraph.addEventListener("click", () => {
          paragraph.classList.remove("selecteableParagraph");
          paragraph.classList.add("selectedParagraph");
        });
      }
    } else {
      for (const paragraph of paragraphs?.values()!) {
        paragraph.classList.remove("selecteableParagraph");
      }
    }
    if (selectedTool === "SELECTION") {
      document!.onselectionchange = (event) => {
        const selection = document?.getSelection();
        return console.log(selection);
        const element = document?.createElement("span");
        element!.innerHTML = selection?.focusNode!.textContent ?? "";
        element!.style.background = "#0f0";
        // @ts-ignore
        selection?.baseNode!.replaceWith(element);
      };
    }
  }, [selectedTool]);

  const handleOpenIndex = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIndexAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (frameLoaded && iframeRef.current) {
      const headings =
        iframeRef.current.contentDocument?.querySelectorAll<HTMLHeadingElement>(
          "h1"
        );

      setIndexes(
        [...headings!]
          .filter((h) => !!h)
          .map((h) => ({
            text: h.innerText ?? "",
            type: h.tagName === "H1" ? IndexType.HEADER1 : IndexType.HEADER2,
          }))
      );
    }
  }, [frameLoaded, iframeRef]);

  return (
    <div
      style={{
        height: "100vh",
        zIndex: 1000,
        position: "absolute",
        top: 0,
        width: "100%",
        overflowY: "hidden",
      }}
    >
      {contentUrl && !progress ? (
        <div
          style={{
            height: "100%",
          }}
        >
          <ContentFrame
            src={contentUrl}
            ref={iframeRef}
            title={chapterData?.name}
            onLoad={() => setFrameLoaded(true)}
          />
          <ToolboxFrame>
            <ToolboxCard>
              {/* <Collapse in={toolboxOpened}> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 8,
                }}
              >
                <IconButton onClick={() => navigate(`/books/${unitId}`)}>
                  <CancelRoundedIcon />
                </IconButton>
                {/* <IconButton onClick={handleOpenIndex}>
                    <ListRoundedIcon />
                  </IconButton> */}
                {/* <IconButton onClick={selectTool("PARAGRAPH_HIGHLIGHT")}>
                    <NotesRoundedIcon
                      color={
                        selectedTool === "PARAGRAPH_HIGHLIGHT"
                          ? "secondary"
                          : "inherit"
                      }
                    />
                  </IconButton> */}
                {/* <IconButton onClick={selectTool("SELECTION")}>
                    <FormatColorTextRoundedIcon
                      color={
                        selectedTool === "SELECTION" ? "secondary" : "inherit"
                      }
                    />
                  </IconButton> */}
              </div>
              {/* </Collapse> */}

              {/* <IconButton
                size="large"
                onClick={() => setToolboxOpened((p) => !p)}
              >
                <KeyboardArrowDownRoundedIcon
                  fontSize="large"
                  sx={{
                    transition: "all 300ms ease-in-out",
                    transform: `rotate(${toolboxOpened ? 180 : 360}deg)`,
                  }}
                />
              </IconButton> */}
            </ToolboxCard>
          </ToolboxFrame>
        </div>
      ) : (
        <Grid my={8} width="100%" container justifyContent="center">
          {progress ? (
            <Grid container direction="column" alignItems="center">
              <Grid item>{progress.text}</Grid>
              <Grid item>
                <LinearProgress
                  variant={
                    progress.value === 100 ? "indeterminate" : "determinate"
                  }
                  value={progress.value}
                  sx={{ width: "100px" }}
                />
              </Grid>
              <Grid item>{prettyBytes(progress.total)}</Grid>
            </Grid>
          ) : (
            <CircularProgress />
          )}
        </Grid>
      )}
      <Menu
        anchorEl={indexAnchorEl}
        open={!!indexAnchorEl}
        onClose={() => setIndexAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorPosition={{
          left: -200,
          top: 0,
        }}
      >
        <Box p={2.5}>
          {indexes ? (
            <Grid container direction="column">
              {indexes.map((index, i) => (
                <Grid item key={i} mb={1}>
                  <Typography variant="body1" component="p">
                    {index.text}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          ) : (
            <LinearProgress />
          )}
        </Box>
      </Menu>
    </div>
  );
}

export default ChapterPage;

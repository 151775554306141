import axios from "axios";
import { unzip } from "unzipit";
import { Chapter, chaptersBasePath, geralBasePath, IContentResponse } from "./api";
import mime from "mime";

export async function downloadGeral(
  content: IContentResponse,
  setProgress: any
) {
  const cache = await caches.open("geral-cache");

  if ((await cache.keys()).length < 20) {
    const { data: geralContent } = await axios.get(content.geral, {
      responseType: "blob",
      onDownloadProgress: (event: ProgressEvent) => {
        setProgress({
          text: `Baixando conteúdo`,
          total: event.total,
          value: (event.loaded / event.total) * 100,
        });
      },
    });

    const geralZip = await unzip(geralContent);

    await Promise.all(
      Object.values(geralZip.entries).map(async (entry) => {
        if (entry.isDirectory) return;

        const res = new Response(await entry.blob(), {
          headers: {
            // @ts-ignore
            "content-type": mime.getType(entry.name.split(".")[1]),
          },
        });

        await cache.put((geralBasePath + entry.name).toLowerCase(), res);
      })
    );
  }
}

export const basePath = chaptersBasePath + `_/`;

export async function downloadChapter(chapter: Chapter, onProgress: (event: ProgressEvent) => void): Promise<string | undefined> {
  const chapterCache = await caches.open(`cache-${chapter.id}`);

  const chapterZipFile = chapter.package;

  const { data: chapterContent } = await axios.get(chapterZipFile, {
    responseType: "blob",
    onDownloadProgress: (event: ProgressEvent) => {
      onProgress(event)
    },
  });

  const zip = await unzip(chapterContent);
  const files = Object.values(zip.entries);

  let indexRes = null;

  for (const entry of files) {
    if (entry.isDirectory) continue;

    const res = new Response(await entry.blob(), {
      headers: {
        // @ts-ignore
        "content-type": mime.getType(entry.name.split(".")[1]),
        "cache-control": "immutable, public",
      },
    });

    if (entry.name.includes("html/index.html")) {
      indexRes = new Response(await entry.blob(), {
        headers: {
          // @ts-ignore
          "content-type": mime.getType(entry.name.split(".")[1]),
          "cache-control": "immutable, public",
        },
      });
    }

    console.log(2)

    await chapterCache.put((basePath + entry.name).toLowerCase(), res);
  }
  const indexFile = files.find((u) => u.name.includes("html/index.html"));

  if (indexRes) {
    await chapterCache.put(
      (basePath + indexFile!.name).toLowerCase() + "#",
      indexRes
    );
    console.log((basePath + indexFile!.name).toLowerCase() + "#");
  }

  return indexFile?.name
}

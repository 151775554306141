import axios from "axios";
import { createContext, useCallback, useMemo, useState } from "react";
import {
  Chapter,
  ChapterListResponse,
  chaptersBasePath,
  getContent,
} from "./api";
import { Map } from "immutable";
import { chunkArray } from "./utils";
import { downloadChapter } from "./geral";
import { useSnackbar } from "notistack";

export interface IDownloadableContent {
  chapter: Chapter;
  name: string;
  id: string;
}

export interface IQueueItem {
  complete: boolean;
  progress: number;
  id: string;
  name: string;
}

export interface IDownloadManagerContext {
  queueDownload: (content: IDownloadableContent) => Promise<void>;
  currentQueue: Map<string, IQueueItem>;
}

export const DownloadManagerContext =
  createContext<IDownloadManagerContext | null>(null);

export const DownloadManagerProvider: React.FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [queue, setQueue] = useState(Map<string, IQueueItem>());

  const queueDownload = useCallback(
    async (content: IDownloadableContent) => {
      if (queue.find((q) => q.id === content.id)) return;
      console.log("Starting to download ", content.name);
      setQueue((prev) =>
        prev.set(content.id, {
          complete: false,
          progress: 0,
          id: content.id,
          name: content.name,
        })
      );

      const chapter = content.chapter;

      return new Promise<void>((resolve) => {
        if (chapter) {
          try {
            const save = async () => {
              await downloadChapter(chapter, (event) => {
                setQueue((v) => {
                  const item = v.get(content.id);
                  if (!item) return v;
                  return v.set(content.id, {
                    ...item,
                    progress: (event.loaded / event.total) * 100,
                  });
                });
              });
  
              setQueue((v) => {
                const item = v.get(content.id);
                if (!item) return v;
                return v.set(content.id, {
                  ...item,
                  progress: 100,
                  complete: true,
                });
              });
            };
  
            save()
              .then(() => {
                setQueue((v) => v.remove(chapter.id));
              })
              .catch((err) => {
                enqueueSnackbar((err as Error).toString(), {
                  variant: "error",
                });
              }).finally(() => {
                resolve()
              })
          } catch (err) {
            enqueueSnackbar((err as Error).toString(), {
              variant: "error",
            });
            setQueue((v) => v.remove(chapter.id));
            resolve()
          }
        }
      })
    },
    [queue]
  );

  const value = useMemo<IDownloadManagerContext>(
    () => ({
      queueDownload,
      currentQueue: queue,
    }),
    [queue, queueDownload]
  );

  return (
    <DownloadManagerContext.Provider value={value}>
      {children}
    </DownloadManagerContext.Provider>
  );
};

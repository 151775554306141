import {
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Paper,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ApiBase,
  Chapter,
  DisciplineEnum,
  getContent,
  IContentResponse,
} from "../api";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { Link as RouterLink } from "react-router-dom";
import { DownloadManagerContext } from "../DownloadManagerContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { ColoredBar } from "../components/common";
import MatematicaIcon from "../assets/disciplines/matematica.png";
import GeografiaIcon from "../assets/disciplines/geografia.png";

type IChapterState =
  | {
      state: "UNDOWNLOADED";
    }
  | {
      state: "DOWNLOADING";
      value: number;
    }
  | {
      state: "COMPLETE";
    };

const ChapterStateItem: React.FC<{
  state: IChapterState;
  onChapterClick: () => void;
  onDeleteClick: () => void;
}> = ({ state, onChapterClick, onDeleteClick }) => {
  if (!state) {
    return <div></div>;
  }

  if (state.state === "COMPLETE") {
    return (
      <React.Fragment>
        <Grid item>
          <IconButton onClick={onDeleteClick}>
            <DeleteRoundedIcon color="error" />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton onClick={onChapterClick}>
            <CheckCircleIcon color="success" />
          </IconButton>
        </Grid>
      </React.Fragment>
    );
  } else if (state.state === "UNDOWNLOADED") {
    return (
      <IconButton onClick={onChapterClick}>
        <DownloadRoundedIcon />
      </IconButton>
    );
  } else {
    return (
      <React.Fragment>
        <Grid item>
          <Typography fontFamily="monospace">{~~state.value}%</Typography>
        </Grid>
        <Grid item>
          <IconButton>
            <div
              style={{
                width: 24,
                height: 24,
              }}
            >
              <CircularProgress
                variant={
                  state.value === 0 || state.value === 100
                    ? "indeterminate"
                    : "determinate"
                }
                value={state.value}
                size="18px"
                thickness={5.0}
              />
            </div>
          </IconButton>
        </Grid>
      </React.Fragment>
    );
  }
};

const DisciplineWrapper = styled("div")<{
  selected: boolean;
}>`
  background: ${(p) => (p.selected ? "rgba(0, 0, 0, 0.1)" : "transparent")};
  width: 100%;
  padding: 10px;
  box-shadow: ${(p) =>
    p.selected ? "inset 0px 0px 20px 0px #818181" : "none"};
  transition: all 200ms ease-in-out;
  cursor: pointer;

  & img {
    width: 100%;
    padding: 2px 17px;
  }

  &:hover {
    box-shadow: ${(p) =>
      p.selected
        ? "inset 0px 0px 20px 0px #818181"
        : "inset 0px 0px 24px 0px #d4d4d4"};
  }
`;

function BookPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const downloadManager = useContext(DownloadManagerContext);

  const [data, setData] = useState<IContentResponse | null>(null);
  const [downloadedChapters, setDownloadedChapters] = useState<Record<
    string,
    boolean
  > | null>(null);
  const [discipline, setDiscipline] = useState<DisciplineEnum>("GEO");

  const handleDownloadedChapters = async (data: IContentResponse) => {
    const downloaded: Record<string, boolean> = {};
    const chapters = data.books
      .map((b) => b.units)
      .flat()
      .map((u) => u.chapters)
      .flat();
    for (const chapter of chapters) {
      const chapterCache = await caches.open(`cache-${chapter.id}`);
      console.log(chapter.package);
      downloaded[chapter.id] = (await chapterCache.keys()).length > 1;
    }
    // @ts-ignore
    window.dm = downloadManager;
    setDownloadedChapters(downloaded);
  };

  const load = async () => {
    try {
      const { data } = await getContent();
      const verified = await ApiBase.get<{
        code: string,
        available: boolean
      }>('/verify?code=MAT')

      if (verified.data.available) {
        setData(data);
      } else {
        let m = {...data}
        for (const book of m.books) {
          for (let i = 0; i < book.units.length; i++) {
            const unit = book.units[i];
            if (unit.discipline === 'MAT') {
              delete book.units[i]
            }
          }
        }
        setData(m)
      }

      
      handleDownloadedChapters(data);
    } catch (err) {
      console.error(err);
    }
  };

  const chapterStates = useMemo<Record<string, IChapterState>>(() => {
    if (!downloadManager || !downloadedChapters) return {};
    const state: Record<string, IChapterState> = {};

    for (const [id, downloaded] of Object.entries(downloadedChapters)) {
      if (downloaded) {
        state[id] = {
          state: "COMPLETE",
        };
      } else {
        const queueItem = downloadManager.currentQueue.get(id);
        if (queueItem) {
          if (queueItem.complete) {
            state[id] = {
              state: "COMPLETE",
            };
          } else {
            state[id] = {
              state: "DOWNLOADING",
              value: queueItem.progress,
            };
          }
        } else {
          state[id] = {
            state: "UNDOWNLOADED",
          };
        }
      }
    }
    return state;
  }, [downloadManager, downloadedChapters]);

  const book = data?.books.find((l) => l.id === id);

  const onChapterClick = async (chapter: Chapter) => {
    const state = chapterStates[chapter.id];
    if (state && state.state === "COMPLETE") {
      navigate(`./chapters/${chapter.id}`);
    } else {
      console.log(downloadManager?.currentQueue);
      await downloadManager?.queueDownload({
        id: chapter.id,
        name: chapter.name,
        chapter: chapter,
      });
      handleDownloadedChapters(data!);
    }
  };

  const onDeleteClick = async (chapter: Chapter) => {
    await caches.delete(`cache-${chapter.id}`);
    handleDownloadedChapters(data!);
  };

  const units = useMemo(
    () => (book ? book.units.filter((u) => u.discipline === discipline) : null),
    [discipline, book]
  );

  useEffect(() => {
    load();
  }, []);
  return (
    <div>
      <Container maxWidth="md">
        {data && book && units ? (
          <React.Fragment>
            <Paper style={{
              marginBottom: 80,
              overflow: 'hidden'
            }}>
              <CardContent>
                <Grid container alignItems="center">
                  <Grid item>
                    <img
                      src={book.cover}
                      alt={book.name}
                      height={110}
                      style={{
                        borderRadius: 3,
                        marginRight: 8,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">{book.name}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />

              <Box mt={2}>
                <Grid container>
                  <Grid item xs={2}>
                    <Typography
                      align="center"
                      variant="body2"
                      fontWeight="bold"
                      my={2}
                    >
                      Componentes
                    </Typography>
                    <Grid container direction="column">
                      <Grid item style={{ width: "100%" }}>
                        <DisciplineWrapper
                          selected={discipline === "GEO"}
                          onClick={() => setDiscipline("GEO")}
                        >
                          <img src={GeografiaIcon} alt="geografia" draggable="false" />
                          <Typography align="center" variant="body2">
                            Geografia
                          </Typography>
                        </DisciplineWrapper>
                      </Grid>
                      <Grid item style={{ width: "100%" }}>
                        <DisciplineWrapper
                          selected={discipline === "MAT"}
                          onClick={() => setDiscipline("MAT")}
                        >
                          <img src={MatematicaIcon} alt="matematica" draggable="false" />
                          <Typography align="center" variant="body2">
                            Matemática
                          </Typography>
                        </DisciplineWrapper>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    sx={{
                      borderLeft: "1px solid rgba(0, 0, 0, 0.08)",
                    }}
                  >
                    <Grid container direction="column">
                      {
                        units.length > 0 ? units.map((unit) => (
                          <Grid item key={unit.id}>
                            <ColoredBar>{unit.name}</ColoredBar>
                            <Grid container direction="column" px={3} py={1}>
                              {unit.chapters.map((chapter) => (
                                <Grid item key={chapter.id}>
                                  <Grid container my={1} alignItems="center">
                                    <Grid
                                      item
                                      sx={{
                                        flex: 1,
                                      }}
                                    >
                                      <Link
                                        component={RouterLink}
                                        sx={{
                                          color: "primary.main",
                                          textDecoration: "grey",
                                          fontSize: 18,
                                        }}
                                        to={`./chapters/${chapter.id}`}
                                      >
                                        {chapter.name}
                                      </Link>
                                    </Grid>
                                    <Grid item>
                                      <Grid container alignItems="center">
                                        <ChapterStateItem
                                          onChapterClick={() =>
                                            onChapterClick(chapter)
                                          }
                                          onDeleteClick={() =>
                                            onDeleteClick(chapter)
                                          }
                                          state={chapterStates[chapter.id]}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        )) : <div>
                          <Typography variant="h4" fontWeight={600} align="center" color="error">
                            Ops!
                          </Typography>
                          <Typography variant="h6" align="center" color="error">
                            Ocorreu um erro ao listar conteúdos. Por favor, entre em contato com o suporte.
                          </Typography>
                        </div>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </React.Fragment>
        ) : (
          <Grid my={8} width="100%" container justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
      </Container>
    </div>
  );
}

export default BookPage;

import {
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { ApiBase, Book, cdnPath, getContent, IContentResponse } from "../api";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { LoginContext } from "../components/LoginContext";
import { ColoredBar } from "../components/common";

const BookImage = styled("img")`
  max-width: 90%;
  max-height: 200px;
  margin-top: 8px;
`;

const BookItem = styled(Grid)`
  border-radius: 12px;
  background: transparent;
  transition: background 200ms ease-in-out;
  margin-bottom: 8px;
  padding: 6px;

  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }

  & p {
    color: ${(p) => p.theme.palette.primary.main};
    text-decoration: none;
  }
`;

function BookSection({ book }: { book: Book }) {
  return (
    <Grid item mx={1} key={book.id}>
      <Link
        to={`/books/${book.id}`}
        style={{
          textDecoration: "none",
        }}
      >
        <BookItem container direction="column" alignItems="center">
          <Grid item display="flex" style={{ justifyContent: "center" }}>
            <BookImage src={book.cover} />
          </Grid>
          <Grid item>
            <Typography>
              {book.name}
            </Typography>
          </Grid>
        </BookItem>
      </Link>
    </Grid>
  );
}

function BooksPage() {
  const [content, setContent] = useState<IContentResponse | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const loginContext = useContext(LoginContext);

  const load = async () => {
    try {
      const { data } = await getContent();
      console.log(data);
      setContent(data);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        enqueueSnackbar((err as any).response?.data?.message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar((err as Error).toString(), {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div>
      <Container maxWidth="md">
        <Paper
        // sx={{
        //   marginTop: 1,
        // }}
        >
          <CardContent>
            <Typography variant="h6">{loginContext?.name}</Typography>
            <Typography variant="body1">
              {loginContext?.grades.join(", ")}
            </Typography>
          </CardContent>
          <Divider />
          {content && content != null ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ColoredBar>7º ano</ColoredBar>
                <Grid container justifyContent="center">
                  {content.books.map((book) => (
                    <BookSection book={book} />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid my={8} width="100%" container justifyContent="center">
              <CircularProgress />
            </Grid>
          )}
        </Paper>
      </Container>
    </div>
  );
}

export default BooksPage;
